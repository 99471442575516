<template>
    <div>
        <div class="row mb-3">
            <div class="col-md-4 col-12">
                <div class="row align-items-center">
                    <div class="col-md-auto col-12">
                        <h6 class="bold mb-0">Tìm theo ID:</h6>
                    </div>
                    <div class="col-md col-12">
                        <input
                            @change="getListJobOrder()"
                            class="form-control input-light"
                            type="text"
                            placeholder="Nhập ID cần tìm"
                            required=""
                            v-model="object_id_search"
                        />
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-12">
                <div class="row align-items-center">
                    <div class="col-md-auto col-12">
                        <h6 class="bold mb-0">Số nhật ký hiển thị tối đa:</h6>
                    </div>
                    <div class="col-md col-12">
                        <select @change="getListJobOrder()" v-model="limit" class="custom-select select-light">
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <datatable stt="true" :columns="columnsTableLogs" :rows="data"> </datatable>
    </div>
</template>
<script>
import { getListSpamSMS } from "../../../../api/services/other"
import datatable from "../../../../components/datatable/datatable"
export default {
    name: "instagram-logs",
    components: {
        datatable
    },
    props: {
        panel: String
    },
    data() {
        return {
            object_id_search: "",
            limit: 100,
            data: [],
            columnsTableLogs: [
                { label: "Username", field: "username" },
                { label: "Tổng số điện thoại", field: "total_phone_number", html: true },
                { label: "Giá", field: "prices" },
                { label: "Số điện thoại", field: "phone_number", textarea: true },
                { label: "Tin nhắn", field: "content", textarea: true },
                { label: "Khởi tạo", field: "created_at", timeago: true }
            ]
        }
    },
    computed: {
        currency() {
            return this.$store.state.site.site.currency
        }
    },
    watch: {
        panel(value) {
            if (value == "logs") {
                this.getListJobOrder()
            }
        }
    },
    methods: {
        getListJobOrder: async function() {
            this.data = []
            let data = await getListSpamSMS(this.type)
            if (data.data) {
                this.data = data.data.map(order => {
                    order.phone_number = order.phone_number ? JSON.parse(order.phone_number).join("\n") : null
                    order.content = order.content ? JSON.parse(order.content).join("\n") : null
                    return order
                })
            }
        }
    }
}
</script>
