<template>
    <div>
        <h4 class="bold text-uppercase">{{ $route.name }}</h4>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a
                    class="nav-link active"
                    @click="handleShowPanel('list')"
                    id="home-tab"
                    data-toggle="tab"
                    href="#home"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                    >Tạo tiến trình</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    @click="handleShowPanel('logs')"
                    id="profile-tab"
                    data-toggle="tab"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                    >Nhật ký Order</a
                >
            </li>
        </ul>
        <div class="tab-content mt-3" id="myTabContent">
            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <div class="row">
                    <div class="col-md-8">
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Nhập danh sách số điện thoại:</h6>
                                <h6 class="text-danger bold font-13">Mỗi dòng tương đương với 1 số</h6>
                            </div>
                            <div class="col-md col-12">
                                <textarea
                                    class="form-control input-light"
                                    rows="5"
                                    v-model="phone_number"
                                    placeholder="Nhập danh sách số điện thoại, mỗi số điện thoại 1 dòng"
                                ></textarea>
                                <span class="badge badge-primary text-white py-1 float-right"
                                    >Số lượng: {{ quantity_phone }}</span
                                >
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Nhập nội dung tin nhắn:</h6>
                                <h6 class="text-danger bold font-13">Mỗi dòng tương đương với 1 tin</h6>
                            </div>
                            <div class="col-md col-12">
                                <textarea
                                    class="form-control input-light"
                                    rows="5"
                                    v-model="content"
                                    placeholder="Nhập nội dung tin nhắn, mỗi số điện thoại 1 dòng"
                                ></textarea>
                                <span class="badge badge-primary text-white py-1 float-right"
                                    >Số lượng: {{ quantity_content }}</span
                                >
                                <h6 @click="random_sms = !random_sms" class="bold">
                                    <i
                                        v-if="random_sms"
                                        class="material-icons font-light mr-1 font-22"
                                        style="vertical-align: middle;"
                                        >check_box</i
                                    >
                                    <i
                                        v-else
                                        class="material-icons-outlined font-light mr-1 font-22"
                                        style="vertical-align: middle;"
                                        >check_box_outline_blank</i
                                    >
                                    Check để gửi nội dung tin nhắn ngẫu nhiên trong danh sách
                                </h6>
                            </div>
                        </div>
                        <div class="row align-items-center form-group">
                            <div class="col-md-3">
                                <h6 class="bold mb-0">Thời gian delay:</h6>
                            </div>
                            <div class="col-md">
                                <div class="row align-items-center">
                                    <div class="col-2">
                                        <h6 class="bold mb-0">Nhỏ nhất:</h6>
                                    </div>
                                    <div class="col-4">
                                        <input
                                            v-model="min_delay"
                                            type="number"
                                            min="600"
                                            class="form-control input light"
                                            placeholder="Thời gian delay nhỏ nhất"
                                        />
                                    </div>
                                    <div class="col-2">
                                        <h6 class="bold mb-0">Lớn nhất:</h6>
                                    </div>
                                    <div class="col-4">
                                        <input
                                            v-model="max_delay"
                                            type="number"
                                            min="600"
                                            class="form-control input light"
                                            placeholder="Thời gian delay lớn nhất"
                                        />
                                    </div>
                                </div>
                                <h6 class="font-14 mt-2">
                                    Hệ thống tự động gửi tin nhắn đến danh sách số điện thoại cách nhau 1 khoảng Delay
                                    bạn đã cài đặt.
                                </h6>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Hẹn giờ gửi tin nhắn:</h6>
                            </div>
                            <div class="col-md row align-items-center">
                                <div class="col-auto">
                                    <h6 @click="auto_send_sms = !auto_send_sms" class="bold">
                                        <i
                                            v-if="auto_send_sms"
                                            class="material-icons font-light mr-1 font-22"
                                            style="vertical-align: middle;"
                                            >check_box</i
                                        >
                                        <i
                                            v-else
                                            class="material-icons-outlined font-light mr-1 font-22"
                                            style="vertical-align: middle;"
                                            >check_box_outline_blank</i
                                        >
                                        Check để hẹn giờ:
                                    </h6>
                                </div>
                                <div v-if="auto_send_sms" class="col-auto">
                                    <datetime
                                        type="datetime"
                                        v-model="time_start"
                                        format="yyyy-MM-dd HH:mm"
                                        input-class="form-control"
                                        :min-datetime="minDatetime"
                                    ></datetime>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-12 text-center bold">
                                <div class="card card-info">
                                    <div class="card-body">
                                        <h5 class="font-bold">
                                            Tổng:
                                            <span class="bold"
                                                ><number
                                                    ref="number1"
                                                    :from="0"
                                                    :to="totalPrice"
                                                    :format="theFormat"
                                                    :duration="1"
                                                    :delay="0"
                                                    easing="Power1.easeOut"
                                                />
                                                {{ currency }}</span
                                            >
                                        </h5>
                                        <h6 class="mb-0">
                                            Bạn sẽ Gửi đến
                                            <span class="bold green"
                                                >{{ quantity_phone }} số điện thoại, mỗi số gửi
                                                {{ quantity_content }} tin</span
                                            >
                                            với giá <span class="green bold">{{ priceSingle | formatNumber }}</span>
                                            {{ currency }} / Tin nhắn
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-12 text-center bold">
                                <button
                                    @click="order()"
                                    :class="['btn btn-block btn-dark-blue btn-lg btn-rounded bold']"
                                    type="submit"
                                >
                                    Tạo tiến trình
                                </button>
                            </div>
                        </div>
                        <div v-if="list_phone_number.length > 0" class="row mt-4 form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Danh sách gửi:</h6>
                            </div>
                            <div class="col-md col-12">
                                <div class="row">
                                    <div v-for="(item, index) in list_phone_number" :key="index" class="col-6 mt-2">
                                        <div class="card card-white h-100">
                                            <div class="card-body py-2">
                                                <h6 class="font-13 mb-0">
                                                    SĐT: <span class="font-bold">{{ item }}</span>
                                                </h6>
                                                <h6 class="font-13 mb-0">
                                                    Nội dung:
                                                    <span class="font-bold">{{ list_random_content[index] }}</span>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card card-red">
                            <div class="card-body color-info">
                                <p class="font-bold">Chú ý:</p>
                                <p>Spam SMS là dịch vụ gửi hàng loạt tin nhắn tới số điện thoại bạn chọn.</p>
                                <p class="">
                                    Mỗi tin nhắn sẽ được hệ thống gửi kèm theo 6 ký tự ngẫu nhiên vào cuối tin nhắn để
                                    tránh nhà mạng phát hiện Spam.
                                </p>
                                <p class="font-bold ">
                                    - Ngiêm cấm gửi các tin nhắn có nội dung vi phạm pháp luật, chính trị, đồ trụy...
                                    Nếu cố tình tạo Seeding bạn sẽ bị trừ hết tiền và band khỏi hệ thống vĩnh viễn, và
                                    phải chịu hoàn toàn trách nhiệm trước pháp luật.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                <spamsmslogs :panel="panel"></spamsmslogs>
            </div>
        </div>
    </div>
</template>
<script>
import { catchError } from "../../../../helpers"
import { orderSpamSMS } from "../../../../api/services/other"
import spamsmslogs from "./spam-sms-logs"
export default {
    name: "spam-sms-order",
    components: { spamsmslogs },
    data() {
        return {
            panel: "order",
            phone_number: "",
            content: "",
            quantity_phone: 0,
            quantity_content: 0,
            random_sms: false,
            min_delay: 600,
            max_delay: 600,
            auto_send_sms: false,
            time_start: new Date().toISOString(),
            minDatetime: new Date().toISOString(),
            list_random_content: [],
            list_phone_number: [],
            list_content: []
        }
    },
    computed: {
        currency() {
            return this.$store.state.site.site.currency
        },
        prices() {
            let tool = this.$store.state.tools.tools.find(tool => tool.id === 86)
            return tool.prices
        },
        priceSingle() {
            let price = parseInt(this.price)
            return Math.ceil(price)
        },
        totalPrice() {
            return this.priceSingle * this.quantity_phone
        }
    },
    watch: {
        phone_number(value) {
            this.list_phone_number = value.length > 0 ? value.match(/.+/g) : []
            this.quantity_phone = value.length > 0 ? value.match(/.+/g).length : 0
            this.randomSMS()
        },
        content(value) {
            this.list_content = value.length > 0 ? value.match(/.+/g) : []
            this.quantity_content = value.length > 0 ? value.match(/.+/g).length : 0
            this.randomSMS()
        },
        random_sms() {
            this.randomSMS()
        }
    },
    async created() {
        this.getPriceSeedingType()
    },
    methods: {
        order: async function() {
            if (
                this.content.length > 1 &&
                this.content
                    .toLowerCase()
                    .match(
                        /dm|đm|đ m|d m|địt mẹ|dit me|lừađảo|conchó|trảtiền|mấtdạy|lừa đảo|con chó|trả tiền|mất dạy|lua dao|con cho|tra tien|mat day/g
                    )
            ) {
                this.$swal(
                    "Lỗi",
                    "Nội dung tin nhắn của bạn vi phạm tiêu chuẩn cộng đồng, hãy nhập lại nội dung cần bình luận!",
                    "error"
                )
                return
            }
            let postData = {
                list_phone_number: this.phone_number.match(/.+/g),
                list_content: this.list_random_content
            }
            let param = {
                post_data: postData,
                note: this.notes,
                min_delay: this.min_delay,
                max_delay: this.max_delay
            }
            if (this.auto_send_sms) {
                param.time_run = this.time_start
            }
            let data = await orderSpamSMS(param)
            if (data.status === 200 && data.success) {
                this.$swal("Thành công", data.message, "success")
                // this.$store.dispatch("USER_GET_INFO")
            } else {
                let noti = ""
                if (data.message) {
                    if (catchError(data) === data.message) {
                        noti = data.message
                    } else noti = catchError(data) + "</br></br>" + data.message
                } else noti = catchError(data)
                this.$swal("Lỗi", noti, "error")
            }
        },
        randomSMS() {
            this.list_random_content = []
            for (let index = 0; index < this.list_phone_number.length; index++) {
                if (this.random_sms) {
                    var randomContent = this.list_content[Math.floor(Math.random() * this.list_content.length)]
                    while (
                        this.list_content.length >= this.list_phone_number.length &&
                        this.list_random_content.includes(randomContent)
                    ) {
                        randomContent = this.list_content[Math.floor(Math.random() * this.list_content.length)]
                    }
                    this.list_random_content.push(randomContent)
                } else {
                    if (index < this.list_content.length) {
                        this.list_random_content.push(this.list_content[index])
                    }
                }
            }
            if (this.list_phone_number.length > this.list_content.length) {
                for (let index = this.list_content.length - 1; index < this.list_phone_number.length; index++) {
                    this.list_random_content.push(this.list_content[this.list_content.length - 1])
                }
            }
        },
        theFormat(number) {
            return number
                ? Math.round(Number(number))
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : 0
        },
        getPriceSeedingType: function() {
            let price = this.prices.find(price => price.package_name.includes("spam_sms"))
            if (price) {
                this.price = price.price
            }
        },
        handleShowPanel: async function(panel) {
            this.panel = panel
        }
    }
}
</script>
